import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Alert from '@mui/material/Alert';
import CustomTextField from './CustomTextField'
import { auth } from './firebaseConfig';
import { signInWithEmailAndPassword, sendEmailVerification } from 'firebase/auth';
import OrganizationDropdown from './OrganizationDropdown'
import './index.css'; // Import the CSS file

const LoginPage = ({onRegisterPage, onLogin}) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState('');
  const [organization, setOrganization] = useState('geha');

  const handleLogin = () => {
    if(!username){
      alert("Missing e-mail")
      return;
    }
    if(!password){
      alert("Missing password")
      return;
    }
    const loginEmail = `${username}@${organization}.resilience-hub.co.il`
    signInWithEmailAndPassword(auth, loginEmail, password)
    .then((userCredential) => {
      const user = auth.currentUser;
      if(!user.emailVerified){
        sendEmailVerification(auth.currentUser)
        .catch((error) => {
          console.log(error.message);
      });
      }
      userCredential.user.getIdToken((idToken) => onLogin(idToken));
    })
    .catch((error) => {
        alert(error.message);
    });
  };


  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault(); // Prevent the default form submission
      handleLogin();
    }
  };

  return (
    <Box className="centered-rectangle-container" sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh', // Full viewport height
        
      }}
      onKeyDown={handleKeyDown}>
              <Box className="centered-rectangle"         sx={{
          width: 500,
          height: 620,
          borderRadius: 7, // Rounded corners
          boxShadow: 3, // Optional: Adds shadow for depth
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        {error && (
          <Alert severity="error" style={{ marginBottom: '10px' }}>
            {error}
          </Alert>
        )}
                <Typography variant="h6" component="h6" gutterBottom>
                    מצא את הכלים
                            </Typography>
        <Typography variant="h4" component="h4" gutterBottom
                  style={{ marginBottom: '10px', borderRadius: '8px' }}
>
          התחברות לאתר
        </Typography>
        <CustomTextField
          label="שם משתמש"
          className="LoginButton"
          fullWidth
          style={{ margin: '24px 30px 24px 24px', borderRadius: '30px' }}
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          InputProps={{
            style: { borderRadius: '30px', borderColor: "#FFFFFF", color: 'white'}
          }}
        />
        <CustomTextField
          label="סיסמא"
          className="LoginButton"
          type={showPassword ? 'text' : 'password'}
          fullWidth
          color='primary'
          style={{ margin: '24px 30px 24px 24px', borderRadius: '30px'}}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          InputProps={{
            color: 'primary',
            style: { borderRadius: '30px', borderColor: "#FFFFFF", color: 'white'},
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                  style={{color: 'white'}}
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            )
          }}
        />
                <OrganizationDropdown organization={organization} setOrganization={setOrganization}/>
        <Button
          variant="contained"
          color="primary"
          className="LoginButton"
          fullWidth
          style={{ marginBottom: '10px', borderRadius: '30px', height: '60px', backgroundColor: 'rgba(255,255,255,0.15)', color: "white"}}
          onClick={handleLogin}
        >
          התחברות
        </Button>
      </Box>
    </Box>
  );
};

export default LoginPage;
