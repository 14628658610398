import React, { useEffect, useState } from 'react';
import { auth } from './firebaseConfig';
import { signOut } from 'firebase/auth';
import UnityWebGL from './UnityWebGL'

const ProtectedPage = ({ onLogout }) => {
    const [userOrganization, setUserOrganization] = useState(null);

    const logout = () => {
        signOut(auth).then(() => {
            onLogout();
        }).catch((error) => {
            alert(error.message);
        });
    };

    useEffect(() => {
        if (auth.currentUser) {
            const organization = auth.currentUser.email.split('@')[1].split('.')[0];
            setUserOrganization(organization);
        }
      }, []);


    return (
        <div>
            <UnityWebGL organization={userOrganization} />
            <button onClick={logout}>Logout</button>
        </div>
    );
};

export default ProtectedPage;
