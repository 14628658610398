import React from 'react';
import { Navigate } from 'react-router-dom';

const AdminRoute = ({ user, isAdmin, element }) => {
  if (!user) {
    return <Navigate to="/login" />;
  }
  if (!isAdmin) {
    return <div>Not an admin. <button onClick={() => window.location.href = '/app'}>Go to App</button></div>;
  }
  return element;
};

export default AdminRoute;
