import React, { useEffect, useState } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { auth, db, functions } from './firebaseConfig';
import { Container } from '@mui/material';
import AdminCreateUser from './AdminCreateUser';
import AdminUserTable from './AdminUserTable';
import { checkAdminStatus } from './utils/authUtils';
import { httpsCallable } from 'firebase/functions';



const AdminPage = () => {
  const [users, setUsers] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isAdmin, setIsAdmin] = useState(false);


  useEffect(() => {
    const fetchAdminData = async () => {
      const organization = auth.currentUser.email.split('@')[1].split('.')[0];
      const { isAdmin, users } = await checkAdminStatus(organization);
      setIsAdmin(isAdmin);
      setUsers(users);
    };

    fetchAdminData();
  }, []);

  const deleteUser = httpsCallable(functions, 'deleteUser');

  const handleDeleteUser = async (userId) => {
    try {
      const organization = auth.currentUser.email.split('@')[1].split('.')[0];
      const result = await deleteUser({ userId, organization });
      console.log(result.data.message);
      // Refresh the user list
      fetchUsers();
    } catch (error) {
      console.error('Error deleting user:', error);
    }
  };

  const fetchUsers = async () => {
    const organization = auth.currentUser.email.split('@')[1].split('.')[0];
    const querySnapshot = await getDocs(collection(db, `users-${organization}`));
    const userList = querySnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    }));
    setUsers(userList);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  if (!isAdmin) {
    return <div>You do not have admin privileges.</div>;
  }

  return (
    <Container>
      <AdminCreateUser fetchUsers={fetchUsers} />
      <AdminUserTable
        users={users}
        page={page}
        rowsPerPage={rowsPerPage}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        handleDeleteUser={handleDeleteUser}
      />
    </Container>
  );
};

export default AdminPage;
