// authUtils.js
import { doc, getDoc, getDocs, collection } from 'firebase/firestore';
import { auth, db } from '../firebaseConfig';


export const checkAdminStatus = async (org) => {
  const user = auth.currentUser;
  if (user) {
    const userDocRef = doc(db, `users-${org}`, user.uid);
    const userDoc = await getDoc(userDocRef);
    if (userDoc.exists() && userDoc.data().admin === true) {
      const querySnapshot = await getDocs(collection(db, `users-${org}`));
      const userList = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      return { isAdmin: true, users: userList };
    }
  }
  return { isAdmin: false, users: [] };
};
