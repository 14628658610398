import React, { useEffect, useState, useCallback } from 'react';
import { Unity, useUnityContext } from 'react-unity-webgl';
import { getProgress, updateProgress } from './firebaseConfig';
import { Box, Button } from '@mui/material';

const UnityWebGL = ({ organization }) => {
  const buildName = "WebTools-Shalvata-2208";
  const [loading, setLoading] = useState(false);

  const urls = organization === 'geha' ? {
    loaderUrl: `https://firebasestorage.googleapis.com/v0/b/psychologytools-6426a.appspot.com/o/WebTools-Geha-2208%2FBuild%2FWebTools-Geha-2208.loader.js?alt=media&token=cb4194e5-f277-481a-905c-4da118def2d2`,
    dataUrl: `https://firebasestorage.googleapis.com/v0/b/psychologytools-6426a.appspot.com/o/WebTools-Geha-2208%2FBuild%2FWebTools-Geha-2208.data?alt=media&token=a27f456b-fcb3-435f-8bdf-4d01c4d1bca0`,
    frameworkUrl: `https://firebasestorage.googleapis.com/v0/b/psychologytools-6426a.appspot.com/o/WebTools-Geha-2208%2FBuild%2FWebTools-Geha-2208.framework.js?alt=media&token=6fcbb068-f012-4ea2-82ca-c9e4a08f1d63`,
    codeUrl: `https://firebasestorage.googleapis.com/v0/b/psychologytools-6426a.appspot.com/o/WebTools-Geha-2208%2FBuild%2FWebTools-Geha-2208.wasm?alt=media&token=e2064923-7e1a-47d4-8394-2d3a34f3aa37`
  } : {
    loaderUrl: `https://firebasestorage.googleapis.com/v0/b/psychologytools-6426a.appspot.com/o/WebTools-Shalvata-2208%2FBuild%2FWebTools-Shalvata-2208.loader.js?alt=media&token=23101fd3-36db-4792-8a26-ae0dcf208711`,
    dataUrl: `https://firebasestorage.googleapis.com/v0/b/psychologytools-6426a.appspot.com/o/WebTools-Shalvata-2208%2FBuild%2FWebTools-Shalvata-2208.data?alt=media&token=bfe7ba14-9b22-43f3-ad78-1e4f5672d196`,
    frameworkUrl: `https://firebasestorage.googleapis.com/v0/b/psychologytools-6426a.appspot.com/o/WebTools-Shalvata-2208%2FBuild%2FWebTools-Shalvata-2208.framework.js?alt=media&token=db0fe165-3bd9-4cae-b570-8eceb8642bfc`,
    codeUrl: `https://firebasestorage.googleapis.com/v0/b/psychologytools-6426a.appspot.com/o/WebTools-Shalvata-2208%2FBuild%2FWebTools-Shalvata-2208.wasm?alt=media&token=e208d64d-cc86-485e-b380-1f6a4c60317d`
  };

  //  const urls = {
  //    loaderUrl: `${buildName}/Build/${buildName}.loader.js`,
  //    dataUrl: `${buildName}/Build/${buildName}.data`,
  //    frameworkUrl: `${buildName}/Build/${buildName}.framework.js`,
  //    codeUrl:`${buildName}/Build/${buildName}.wasm`
  //  }

  const { unityProvider, isLoaded, loadingProgression, sendMessage, addEventListener, removeEventListener, requestFullscreen } = useUnityContext({
    loaderUrl: urls.loaderUrl,
    dataUrl: urls.dataUrl,
    frameworkUrl: urls.frameworkUrl,
    codeUrl: urls.codeUrl,
  });

  const handleLoadProgress = useCallback(async () => {
    console.log("HANDLE LOAD PROGRESS FOR ORG " + organization);
    let progress = await getProgress(organization);
    sendMessage("FirebaseManager", "LoadUserProgress", progress);
  }, [sendMessage, organization]);

  const handleStoreProgress = useCallback((data) => {
    console.log("The stored data: " + data);
    updateProgress(organization, data);
  }, [organization]);

  useEffect(() => {
    addEventListener("RequestUserProgress", handleLoadProgress);
    return () => {
      removeEventListener("RequestUserProgress", handleLoadProgress);
    };
  }, [addEventListener, removeEventListener, handleLoadProgress]);

  useEffect(() => {
    addEventListener("StoreUserProgress", handleStoreProgress);
    return () => {
      removeEventListener("StoreUserProgress", handleStoreProgress);
    };
  }, [addEventListener, removeEventListener, handleStoreProgress]);

  if (loading) {
    return <p>Loading URLs...</p>;
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        textAlign: 'center',
      }}
    >
      {!isLoaded && <p>Loading Application... {Math.round(loadingProgression * 100)}%</p>}
      <Unity unityProvider={unityProvider} style={{ width: '50%', height: '50%' }} />
      <Button
        variant="contained"
        color="primary"
        onClick={() => requestFullscreen(true)}
        sx={{ mt: 2 }}
      >
        Enter Full Screen
      </Button>
    </Box>
  );
};

export default UnityWebGL;
