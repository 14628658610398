import React, { useState, useEffect } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { auth, listenForAuthChanges } from './firebaseConfig';
import LoginPage from './LoginPage';
import RegisterPage from './RegisterPage';
import ProtectedPage from './ProtectedPage';
import AdminPage from './AdminPage';
import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline } from '@mui/material';
import { CacheProvider } from '@emotion/react';
import { theme, cacheRtl } from './theme';
import Header from './Header';
import { checkAdminStatus } from './utils/authUtils';
import ProtectedRoute from './ProtectedRoute'
import AdminRoute from './AdminRoute';

const App = () => {
  const [user, setUser] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = listenForAuthChanges(async (user) => {
      setUser(user);
      setIsLoading(false);
      if (user) {
        const organization = user.email.split('@')[1].split('.')[0];
        try {
          const { isAdmin } = await checkAdminStatus(organization);
          setIsAdmin(isAdmin);
        } catch (error) {
          console.error('Error checking admin status:', error);
        }
      }
    });

    return () => unsubscribe();
  }, []);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <BrowserRouter>
      <CacheProvider value={cacheRtl}>
        <ThemeProvider theme={theme}>
          <Header user={user} onLogout={() => setUser(null)} isAdmin={isAdmin} />
          <CssBaseline />
          <Routes>
            <Route path="/login" element={user ? <Navigate to="/app" /> : <LoginPage />} />
            <Route path="/app" element={<ProtectedRoute user={user} element={<ProtectedPage />} />} />
            <Route path="/admin" element={<AdminRoute user={user} isAdmin={isAdmin} element={<AdminPage />} />} />
            <Route path="*" element={<Navigate to="/login" />} />
          </Routes>
        </ThemeProvider>
      </CacheProvider>
    </BrowserRouter>
  );
};

export default App;
