import React, { useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import AboutModal from './AboutModal';
import { signOut } from 'firebase/auth';
import { auth } from './firebaseConfig';

const Offset = styled('div')(({ theme }) => theme.mixins.toolbar);

const Header = ({ user, onLogout, isAdmin }) => {
  const [open, setOpen] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const logout = () => {
    signOut(auth)
      .then(() => {
        onLogout();
      })
      .catch((error) => {
        alert(error.message);
      });
  };

  const handleAdminButton = () => {
    if (location.pathname !== "/admin") {
      navigate('/admin');
    } else {
      navigate('/app');
    }
  };

  return (
    <>
      <AppBar position="fixed" elevation={0} sx={{ bgcolor: 'rgba(255, 255, 255, 0.2)', backdropFilter: 'blur(10px)' }}>
        <Toolbar>
          <Box sx={{ flexGrow: 1 }}>
            <Button
              variant="outlined"
              color="primary"
              sx={{
                marginLeft: '10px',
                width: '120px',
                marginBottom: '10px',
                marginTop: '10px',
                borderRadius: '30px',
                height: '40px',
                backgroundColor: 'rgba(0,0,0,0.5)',
                color: 'white',
                ':hover': {
                  borderColor: 'primary.border',
                  color: 'white',
                  backgroundColor: 'rgba(0,0,0,0.7)',
                },
              }}
              onClick={handleClickOpen}
            >
              אודות
            </Button>
          </Box>
          <Box sx={{ flexGrow: 0 }}>
            {isAdmin && (
              <Button
                variant="outlined"
                color="primary"
                onClick={handleAdminButton}
                sx={{
                  marginLeft: '10px',
                  width: '120px',
                  marginBottom: '10px',
                  marginTop: '10px',
                  borderRadius: '30px',
                  height: '40px',
                  backgroundColor: 'rgba(0,0,0,0.5)',
                  color: 'white',
                  ':hover': {
                    borderColor: 'primary.border',
                    color: 'white',
                    backgroundColor: 'rgba(0,0,0,0.7)',
                  },
                }}
              >
                {location.pathname !== "/admin" ? "ניהול" : "חזור לאפליקציה"}
              </Button>
            )}
          </Box>
          <Box sx={{ flexGrow: 0 }}>
            {user && (
              <Button
                variant="outlined"
                color="primary"
                to="/"
                onClick={logout}
                sx={{
                  marginLeft: '10px',
                  width: '120px',
                  marginBottom: '10px',
                  marginTop: '10px',
                  borderRadius: '30px',
                  height: '40px',
                  backgroundColor: 'rgba(0,0,0,0.5)',
                  color: 'white',
                  ':hover': {
                    borderColor: 'primary.border',
                    color: 'white',
                    backgroundColor: 'rgba(0,0,0,0.7)',
                  },
                }}
              >
                התנתק
              </Button>
            )}
          </Box>
        </Toolbar>
      </AppBar>
      <Offset />
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          sx: {
            backgroundColor: 'rgba(0, 0, 0, 0)', // Adjust this value for transparency
          },
        }}
      >
        <AboutModal handleClose={handleClose} />
      </Dialog>
    </>
  );
};

export default Header;
