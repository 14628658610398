import React from 'react';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Typography } from '@mui/material';

const AboutModal = ({handleClose}) => {

    return (
        <Box className="about-centered-rectangle"         sx={{
            width: 600,
            height: 1000,
            borderRadius: 7, // Rounded corners
            boxShadow: 3, // Optional: Adds shadow for depth
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
<Typography color="primary">
    <h2>למצוא את הכלים</h2>
    <p>
    "מצא את הכלים" הוא סימולציית VR חדשנית שנועדה במיוחד לעובדים סוציאליים לשיפור מיומנויותיהם בטיפול בטראומה בקרב מטופלים. טכנולוגיה מתקדמת זו מטמיעה את המשתמשים בסביבות אינטראקטיביות מציאותיות, בהן הם נתקלים בתרחישים שונים המדמים מצבים מהחיים האמיתיים. על ידי מעורבות בחוויות וירטואליות אלו, עובדים סוציאליים יכולים לתרגל את תגובותיהם לאתגרים רגשיים ופסיכולוגיים מורכבים, ולעזור להם לפתח חשיבה ביקורתית, אמפתיה ומיומנויות תקשורת אפקטיביות. טבעה הטבולי של ה-VR מאפשר הבנה עמוקה יותר של השפעת הטראומה על הפרטים, ומקל על חווית למידה עמוקה יותר בהשוואה לשיטות ההכשרה המסורתיות.
    </p>
    <p>
    הסימולציה כוללת סדרת מודולים המכסים היבטים שונים של טיפול בטראומה, החל מהערכה ראשונית של המטופל ועד לאסטרטגיות טיפול ארוכות טווח. כל מודול מציג תרחישים ייחודיים הדורשים מהעובד הסוציאלי לזהות התערבויות וכלים מתאימים לתמיכה בתהליך ההחלמה של המטופל. לדוגמה, תרחיש אחד עשוי לכלול ילד שהיה עד לאלימות במשפחה, בעוד שאחר עשוי להתמקד במבוגר המתמודד עם ההשלכות של אסון טבע. "מצא את הכלים" מספק משוב והנחיות בזמן אמת, המאפשרים למשתמשים ללמוד מהפעולות שלהם ולשפר את הטכניקות בסביבה בטוחה ומבוקרת.
    </p>
    <p>
    אחד היתרונות המרכזיים של "מצא את הכלים" הוא יכולתו לדמות את העוצמה הרגשית והבלתי צפויות של אינטראקציות אמיתיות עם נפגעי טראומה. על ידי התמודדות עם אתגרים אלה בהגדרות וירטואליות, עובדים סוציאליים יכולים לבנות חוסן וביטחון, להבטיח שהם מוכנים יותר למצבים קליניים אמיתיים. בנוסף, סימולציית ה-VR מקדמת הבנה עמוקה יותר של הדרכים המגוונות בהן טראומה יכולה להתבטא, ומדגישה את חשיבות הטיפול המותאם אישית והמרוכז במטופל. בסופו של דבר, "מצא את הכלים" שואף לשפר את יעילותם של עובדים סוציאליים בטיפול בטראומה, להוביל לתוצאות טובות יותר עבור המטופלים ולטפח גישה חומלת יותר לטיפול בטראומה.
    </p>
</Typography>
            </Box>
    );
};

export default AboutModal;
