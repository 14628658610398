import React from 'react';
import TextField from '@mui/material/TextField';
import { styled } from '@mui/material/styles';

const CustomTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: 'rgba(255,255,255,0.5)', // Default border color
    },
    '&:hover fieldset': {
      borderColor: theme.palette.primary.main, // Hover border color
    },
    '&.Mui-focused fieldset': {
      borderColor: theme.palette.primary.main, // Focused border color
    },
    borderRadius: '30px',
  },
  '& .MuiInputLabel-root': {
    color: 'white', // Default label color
  },
  '& .MuiInputLabel-root.Mui-focused': {
    color: theme.palette.primary.main, // Focused label color
  },
  '& .MuiInputBase-input': {
    color: 'white', // Default input text color
  },
  '& .MuiInputBase-input.Mui-focused': {
    color: theme.palette.primary.main, // Focused input text color
  },
}));

export default CustomTextField;
