import React, { useState } from 'react';
import {
  Table, TableBody, TableCell, TableContainer,
  TableHead, TablePagination, TableRow, Paper, Box, Button, TableSortLabel, Typography
} from '@mui/material';

function parseProgressData(data) {
  let parsedProgressData = {}
  if(data){
    parsedProgressData = JSON.parse(data)
  } else {
    return "did not begin";
  }
  if (!parsedProgressData || !parsedProgressData.progress || parsedProgressData.progress.length === 0) {
    return "did not begin";
  } 

  const progressStrings = parsedProgressData.progress.map(chapter => {
    const subChapters = chapter.SubChapters;
    const totalProgress = subChapters.reduce((acc, subChapter) => acc + subChapter.Progress, 0);
    const averageProgress = totalProgress / subChapters.length;

    if (chapter.ChapterName == "UI"){
      return '';
    }

    if (averageProgress === 0) {
      return `${chapter.ChapterName}: did not begin`;
    } else {
      const progressPercentage = Math.round(averageProgress * 100);
      return `${chapter.ChapterName}: ${progressPercentage}% completed`;
    }
  });

  return progressStrings.join(', ');
}

const AdminUserTable = ({ users, page, rowsPerPage, handleChangePage, handleChangeRowsPerPage, handleDeleteUser }) => {
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('registrationDate');
  const [isDeleting, setIsDeleting] = useState(false);

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const sortedUsers = users.sort((a, b) => {
    if (orderBy === 'registrationDate' || orderBy === 'lastLogin') {
      return (new Date(a[orderBy]) < new Date(b[orderBy]) ? -1 : 1) * (order === 'asc' ? 1 : -1);
    } else {
      return (a[orderBy] < b[orderBy] ? -1 : 1) * (order === 'asc' ? 1 : -1);
    }
  });

  const handleDeleteClick = async (userId) => {
    setIsDeleting(true);
    await handleDeleteUser(userId);
    setIsDeleting(false);
  };

  const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    const options = {
      year: '2-digit',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
    };
    return date.toLocaleDateString('en-GB', options).replace(',', '');
  };

  return (
    <Box
      sx={{
        width: 1200,
      }}
    >
      <Paper sx={{ backgroundColor: 'rgba(0, 0, 0, 0.6)', color: 'white' }}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sortDirection={orderBy === 'username' ? order : false} align="center">
                  <TableSortLabel
                    active={orderBy === 'username'}
                    direction={orderBy === 'username' ? order : 'asc'}
                    onClick={() => handleRequestSort('username')}
                    sx={{
                      color: 'white',
                      '&.MuiTableSortLabel-root.MuiTableSortLabel-active': {
                        color: 'white',
                      },
                    }}
                  >
                    <Typography variant="h6" component="div">
                      שם משתמש
                    </Typography>
                  </TableSortLabel>
                </TableCell>
                <TableCell sortDirection={orderBy === 'registrationDate' ? order : false} align="center">
                  <TableSortLabel
                    active={orderBy === 'registrationDate'}
                    direction={orderBy === 'registrationDate' ? order : 'asc'}
                    onClick={() => handleRequestSort('registrationDate')}
                    sx={{
                      color: 'white',
                      '&.MuiTableSortLabel-root.MuiTableSortLabel-active': {
                        color: 'white',
                      },
                    }}
                  >
                    <Typography variant="h6" component="div">
                      תאריך הרשמה
                    </Typography>
                  </TableSortLabel>
                </TableCell>
                <TableCell sortDirection={orderBy === 'lastLogin' ? order : false} align="center">
                  <TableSortLabel
                    active={orderBy === 'lastLogin'}
                    direction={orderBy === 'lastLogin' ? order : 'asc'}
                    onClick={() => handleRequestSort('lastLogin')}
                    sx={{
                      color: 'white',
                      '&.MuiTableSortLabel-root.MuiTableSortLabel-active': {
                        color: 'white',
                      },
                    }}
                  >
                    <Typography variant="h6" component="div">
                      התחבר לאחרונה
                    </Typography>
                  </TableSortLabel>
                </TableCell>
                <TableCell align="center">
                  <Typography variant="h6" component="div">
                    התקדמות
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography variant="h6" component="div">
                    פעולות
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sortedUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((user) => (
                <TableRow key={user.id}>
                  <TableCell align="center" sx={{ color: 'white' }}>{user.username}</TableCell>
                  <TableCell align="center" sx={{ color: 'white' }}>{formatDate(user.registrationDate)}</TableCell>
                  <TableCell align="center" sx={{ color: 'white' }}>{formatDate(user.lastLogin)}</TableCell>
                  <TableCell align="center" sx={{ color: 'white' }}>{parseProgressData(user.progress)}</TableCell>
                  <TableCell align="center">
                    <Button
                      variant="contained"
                      onClick={() => handleDeleteClick(user.id)}
                      disabled={isDeleting}
                      sx={{
                        backgroundColor: 'rgba(255, 255, 255, 0.15)',
                        color: 'white',
                        borderRadius: '30px',
                        ':hover': {
                          backgroundColor: 'rgba(255, 255, 255, 0.25)',
                        },
                      }}
                    >
                      Delete
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50]}
          component="div"
          count={users.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          sx={{ color: 'white' }}
        />
      </Paper>
    </Box>
  );
};

export default AdminUserTable;
