import { initializeApp } from 'firebase/app';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { getStorage } from 'firebase/storage';
import { getFirestore, doc, getDoc, updateDoc } from "firebase/firestore";


const firebaseConfig = {
    apiKey: "AIzaSyD-ZINsFF4sug9phXDf0S2F8ZVqVfQGwTQ",
    authDomain: "psychologytools-6426a.firebaseapp.com",
    projectId: "psychologytools-6426a",
    storageBucket: "psychologytools-6426a.appspot.com",
    messagingSenderId: "YOUR_MESSAGING_SENDER_ID",
    appId: "psychologytools-6426a"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const functions = getFunctions(app);
const storage = getStorage(app);
const db = getFirestore(app);

const registerUser = async (uid, password) => {
    const registerUser = httpsCallable('registerUser');
    try {
      const result = await registerUser({ uid, password });
      console.log(result.data.message);
    } catch (error) {
      console.error('Error registering user:', error);
    }
  };

  async function getProgress(organization) {
    try {
      console.log(organization)
      // Check if user is authenticated
      const uid = auth.currentUser.uid;

      // Construct the collection name
      const collectionName = `users-${organization}`;

      // Reference to the document
      const docRef = doc(db, collectionName, uid);

      // Retrieve the document
      const docSnap = await getDoc(docRef);  
      if (docSnap.exists()) {
        // Get the progress from the document data
        const data = docSnap.data();
        if (data.hasOwnProperty("progress")){
          return data.progress;
        } else {
          return "";
        }
      } else {
        console.log("No such document!");
        return null;
      }
    } catch (error) {
      console.error("Error getting document:", error);
    }
  }

  async function updateProgress(organization, progressValue) {
    try {
      console.log(organization)
      // Check if user is authenticated
      const uid = auth.currentUser.uid;

      // Construct the collection name
      const collectionName = `users-${organization}`;
  
      // Reference to the document
      const docRef = doc(db, collectionName, uid);
  
      // Update the progress field
      await updateDoc(docRef, {
        progress: progressValue
      });
  
      console.log("Progress updated successfully");
    } catch (error) {
      console.error("Error updating progress:", error);
    }
  }
  
  const listenForAuthChanges = (callback) => {
    return onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is signed in, pass user object to callback
        callback(user);
      } else {
        // User is signed out, pass null to callback
        callback(null);
      }
    });
  };


export { auth, functions, storage, db, registerUser, getProgress,updateProgress, listenForAuthChanges };
