import React from 'react';
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';

const OrganizationDropdown = ({ organization, setOrganization }) => {

  const handleChange = (event) => {
    setOrganization(event.target.value);
  };

  return (
    <FormControl 
      fullWidth 
      variant="outlined" 
      style = {{margin: '24px 30px 24px 24px'}}
      sx={{ 
        minWidth: 120, 
        '& .MuiOutlinedInput-root': {
          color: 'white',
          '& fieldset': {
            borderColor: 'white',
            borderRadius: '30px',
          },
          '&:hover fieldset': {
            borderColor: 'white',
          },
          '&.Mui-focused fieldset': {
            borderColor: 'white',
          },
        },
        '& .MuiInputLabel-root': {
          color: 'white',
        },
        '& .MuiSelect-icon': {
          color: 'white',
        },
      }}
    >
      <InputLabel id="dropdown-label">ארגון</InputLabel>
      <Select
        labelId="dropdown-label"
        value={organization}
        onChange={handleChange}
        label="בחר אפשרות"
      >
        <MenuItem value="geha">גהה</MenuItem>
        <MenuItem value="shalvata">שלוותה</MenuItem>
      </Select>
    </FormControl>
  );
};

export default OrganizationDropdown;
