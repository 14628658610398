import { createTheme } from '@mui/material/styles';
import { create } from 'rtl-css-js';
import { prefixer } from 'stylis';
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';
import rtlPlugin from 'stylis-plugin-rtl';


// Create a theme instance
const theme = createTheme({
    direction: 'rtl', // Set the direction to RTL
  typography: {
    fontFamily: 'Open Sans, sans-serif',
    h4: {
      fontFamily: 'Open Sans, sans-serif',
      fontWeight: 700,
      color: '#FFFFFF'
    },
    h6: {
      fontFamily: 'Open Sans, sans-serif',
      fontWeight: 600,
      color: '#FFFFFF'
    },
    fontWeight: 600
    // Add other variants as needed
  },
  palette: {
    primary: {
      main: '#FFFFFF',
      fade: 'rgba(255,255,255,0.5)',
      border: 'rgba(158, 81, 255, 1)'
    },
    secondary: {
      main: '#000000',
    },
  },
});
// Create a cache with RTL plugin
const cacheRtl = createCache({
    key: 'muirtl',
    stylisPlugins: [prefixer, rtlPlugin],
});
  
  export { theme, cacheRtl };

