import React, { useState } from 'react';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { auth } from './firebaseConfig';
import {  Button, Box, Typography } from '@mui/material';
import CustomTextField from './CustomTextField'

const AdminCreateUser = ({ fetchUsers }) => {
  const [newUsername, setNewUsername] = useState('');
  const [newEmail, setNewEmail] = useState('');

  const handleAddUser = async () => {
    try {
      const organization = auth.currentUser.email.split('@')[1].split('.')[0];
      const functions = getFunctions();
      const createUserCall = httpsCallable(functions, 'createUserAndSendEmail');
      console.log('Sending request with:', { username: newUsername, email: newEmail, organization: organization });

      const result = await createUserCall({
        username: newUsername,
        email: newEmail,
        organization: organization
      });

      console.log(result);
      setNewUsername('');
      setNewEmail('');
      await fetchUsers();
      alert("User added successfully!");
    } catch (error) {
      console.error("Error adding user:", error.message);
      alert("Error adding user. Check console for details.");
    }
  };

  return (
    <Box className="centered-rectangle"         sx={{
      width: 1200,
      height: 300,
      borderRadius: 7, // Rounded corners
      boxShadow: 3, // Optional: Adds shadow for depth
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginBottom: '50px'
    }}>
                      <Typography variant="h6" component="h6" gutterBottom>
הוסף משתמש חדש
                            </Typography>
      <CustomTextField
        label="שם משתמש"
        value={newUsername}
        onChange={(e) => setNewUsername(e.target.value)}
        fullWidth
        margin="normal"
      />
      <CustomTextField
        label="אי-מייל"
        value={newEmail}
        onChange={(e) => setNewEmail(e.target.value)}
        fullWidth
        margin="normal"
      />
      <Button
          variant="contained"
          color="primary"
          className="LoginButton"
          fullWidth
          style={{ marginBottom: '10px', borderRadius: '30px', height: '60px', backgroundColor: 'rgba(255,255,255,0.15)', color: "white"}}
        onClick={handleAddUser}
      >
        Add User
      </Button>
    </Box>
  );
};

export default AdminCreateUser;
